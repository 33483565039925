import React, { useState, useEffect, ChangeEvent } from "react";
import { TextField, ITextFieldProps } from "@fluentui/react";

interface PersistentTextFieldProps extends ITextFieldProps {
    storageKey: string;
}

const PersistentTextField: React.FC<PersistentTextFieldProps> = props => {
    const [value, setValue] = useState<string>("");

    // Load the stored value from local storage when the component mounts
    useEffect(() => {
        const storedValue = localStorage.getItem(props.storageKey);
        if (storedValue) {
            setValue(storedValue);
        }
    }, [props.storageKey]);

    const onChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (newValue === undefined) return;
        // Update the value in the state and local storage
        setValue(newValue);
        localStorage.setItem(props.storageKey, newValue);

        // Call the parent's onChange if it exists
        if (props.onChange) {
            props.onChange(event, newValue);
        }
    };

    return <TextField {...props} value={value} onChange={onChange} />;
};

export default PersistentTextField;
