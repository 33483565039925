import React, { useState, useEffect } from "react";
import { ChoiceGroup, IChoiceGroupOption, IChoiceGroupProps } from "@fluentui/react";
import { Approaches } from "../api";

interface PersistentChoiceGroupProps extends IChoiceGroupProps {
    storageKey: string;
}

const PersistentChoiceGroup: React.FC<PersistentChoiceGroupProps> = props => {
    const [selectedKey, setSelectedKey] = useState<Approaches>(Approaches.RetrieveThenRead);

    // Load the stored value from local storage when the component mounts
    useEffect(() => {
        const storedValue = localStorage.getItem(props.storageKey);
        console.log("storedValue", storedValue);
        if (storedValue) {
            setSelectedKey(storedValue as Approaches);
        }
    }, [props.storageKey]);

    const onChange = (_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, option?: IChoiceGroupOption) => {
        if (!option) return;
        // Update the value in the state and local storage
        setSelectedKey(option.key as Approaches);
        localStorage.setItem(props.storageKey, option.key.toString());

        // Call the parent's onChange if it exists
        if (props.onChange) {
            props.onChange(_ev, option);
        }
    };

    return <ChoiceGroup {...props} selectedKey={selectedKey} onChange={onChange} />;
};

export default PersistentChoiceGroup;
