import {
    AskRequest,
    AskResponse,
    ChatRequest,
    CustomerRequest,
    CustomerResponse,
    LoginRequest,
    LoginResponse,
    NormalResponse,
    UploadFilesRequest
} from "./models";

import { firebaseDb } from "./firebase";
import { FileArray } from "chonky";

export async function loginApi(creds: LoginRequest): Promise<LoginResponse> {
    const token = localStorage.getItem("access_token");
    const response = await fetch("/api/login", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(creds)
    });

    const parsedResponse: LoginResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const token = localStorage.getItem("access_token");
    const response = await fetch("/api/ask", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const token = localStorage.getItem("access_token");
    const response = await fetch("/api/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            },
            customer_url: options.customer_url
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function fetchCustomerApi(request: CustomerRequest): Promise<CustomerResponse> {
    let doc;
    try {
        doc = await firebaseDb.collection("customers").doc(request.url).get();
    } catch (e) {
        console.error(e);
        let errMessage = e;
        if (e instanceof Error) {
            errMessage = e.message;
        }
        return { error: errMessage as string };
    }
    if (!doc.exists) {
        return { error: "Customer does not exist" };
    }
    console.log(doc.data());
    return {
        //TODO
    };
}

export async function reindexCustomer(options: CustomerRequest): Promise<NormalResponse> {
    const token = localStorage.getItem("access_token");
    const response = await fetch(`/api/customer/${options.url}/reindex`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });

    const parsedResponse: NormalResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function getFiles(options: CustomerRequest): Promise<FileArray> {
    const token = localStorage.getItem("access_token");
    const response = await fetch(`/api/customer/${options.url}/files`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        }
    });

    if (response.status > 299 || !response.ok) {
        throw Error(response.statusText || "Unknown error");
    }

    return response.json();
}

export async function uploadFiles(request: UploadFilesRequest): Promise<NormalResponse> {
    const token = localStorage.getItem("access_token");
    const uploadUrl = `/api/customer/${request.customerUrl}/files`;
    const response = await fetch(uploadUrl, {
        method: "POST",
        body: request.formData,
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
    const parsedResponse = await response.json();
    if (response.status != 201 && response.status != 200) {
        throw Error(`Status: ${response.status} - ${response.statusText}` || "Unknown error");
    }

    return parsedResponse;
}
export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}
