import React, { useEffect, useState } from "react";

import styles from "./uploader.module.css";
import MultipleFileUploader from "../MultipleFileUploader/MultipleFileUploader";
import { getCustomerUrl } from "../../../helpers/urlUtils";
import { MessageBar, MessageBarType, Spinner } from "@fluentui/react";

import { uploadFiles } from "../../../api";
import { FileBrowser } from "../FileBrowser/FileBrowser";
import { FileArray } from "chonky";
const Uploader = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [customerUrl, setCustomerUrl] = useState<string>("");

    useEffect(() => {
        setCustomerUrl(getCustomerUrl());
    }, []);

    async function onFileUpload(formData: FormData) {
        try {
            setIsLoading(true);
            const response = await uploadFiles({
                customerUrl: customerUrl,
                formData: formData
            });
            setSuccessMessage(response.message!);
        } catch (err: any) {
            setErrorMessage(err.message);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className={styles.uploaderContainer}>
            <div className={styles.uploaderTopSection}>
                <h1 className={styles.uploaderTitle}>Manage your files</h1>
                <h1 className={styles.uploaderTitle}>Upload files for {getCustomerUrl()}</h1>
                <div className={styles.uploaderQuestionInput}>
                    <MultipleFileUploader onUpload={onFileUpload} />
                </div>
                {isLoading && <Spinner label="Uploading..." />}
                {errorMessage && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        onDismiss={() => setErrorMessage(null)}
                        dismissButtonAriaLabel="Close"
                    >
                        {errorMessage}
                    </MessageBar>
                )}
                {successMessage && (
                    <MessageBar
                        messageBarType={MessageBarType.success}
                        isMultiline={false}
                        onDismiss={() => setErrorMessage(null)}
                        dismissButtonAriaLabel="Close"
                    >
                        {successMessage}
                    </MessageBar>
                )}
            </div>
        </div>
    );
};

export default Uploader;
