import {matchPath} from "react-router";

export function getCustomerUrl():string {
    const match = matchPath("/customer/:customerUrl/*", window.location.pathname)
    if (!match) {
        console.info("Customer doesn't exist in path");
        return '';
    }
    const { customerUrl } = match.params
    return customerUrl!;
}