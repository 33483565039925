import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter} from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { loadTheme, createTheme, Theme } from "@fluentui/react";

import "./index.css";

import SelectCustomer from "./pages/selectClient/SelectCustomer"
import Layout from "./pages/layout/Layout";
import OneShot from "./pages/oneshot/OneShot";
import FileManager from "./pages/fileManager/FileManager";
import Chat from "./pages/chat/Chat";
import LoginModal from "./components/LoginModal";
import { setChonkyDefaults } from 'chonky';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';


setChonkyDefaults({ iconComponent: ChonkyIconFA });
const myTheme = createTheme({
    palette: {
        themePrimary: "#364fff",
        themeLighterAlt: "#02030a",
        themeLighter: "#090c29",
        themeLight: "#10174d",
        themeTertiary: "#202e99",
        themeSecondary: "#2f44e0",
        themeDarkAlt: "#4a5fff",
        themeDark: "#6678ff",
        themeDarker: "#8e9bff",
        neutralLighterAlt: "#3a3c48",
        neutralLighter: "#41434f",
        neutralLight: "#4c4e5b",
        neutralQuaternaryAlt: "#535563",
        neutralQuaternary: "#595b69",
        neutralTertiaryAlt: "#727482",
        neutralTertiary: "#c8c8c8",
        neutralSecondary: "#d0d0d0",
        neutralSecondaryAlt: "#d0d0d0",
        neutralPrimaryAlt: "#dadada",
        neutralPrimary: "#ffffff",
        neutralDark: "#f4f4f4",
        black: "#f8f8f8",
        white: "#343641"
    }
});

loadTheme(myTheme);

initializeIcons();

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

    // useEffect that checks if the user is authenticated
    useEffect(() => {
        const accessToken = localStorage.getItem("access_token");
        if (accessToken) {
            setIsAuthenticated(true);
        }
    }, []);

    if (!isAuthenticated) {
        return <LoginModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} setIsAuthenticated={setIsAuthenticated} />;
    } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Layout isAuthenticated={isAuthenticated} />}>
                        <Route index element={<SelectCustomer />} />
                        <Route path="/customer/:clientUrl/">
                            <Route index element={<Chat />}/>
                            <Route path="qa" element={<OneShot />} />
                            <Route path="files" element={<FileManager />} />
                        </Route>
                    </Route>
                </Routes>
            </BrowserRouter>
        );
    }
};

export default App;

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        {/* <ThemeProvider theme={appTheme}> */}
        <App />
        {/* </ThemeProvider> */}
    </React.StrictMode>
);
