import firebase from "firebase/app";
import "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDE3KX3S7vo0E3nc1lGoX7mrOLFiHaRD8E",
    authDomain: "jepeto-ai.firebaseapp.com",
    projectId: "jepeto-ai",
    storageBucket: "jepeto-ai.appspot.com",
    messagingSenderId: "1001028639900",
    appId: "1:1001028639900:web:a9687d3ca392ae6df06159",
    measurementId: "G-27GXM0SCZ2"
};

// Initialize Firebase
export const firebaseApp = firebase.initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const firebaseDb = firebase.firestore(firebaseApp);