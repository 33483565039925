import { useState } from "react";
import { DefaultButton, TextField } from "@fluentui/react";
import styles from "./SelectCustomer.module.css";

const SelectCustomer = () => {
    const [customerUrl, setCustomerUrl] = useState<string>("");

    const redirectToCustomerUrl = () => {
        window.location.pathname = `/customer/${customerUrl}/`
    }

    return (
        <div className={styles.container}>
            <div>
                <TextField
                    className={styles.customerInputTextArea}
                    placeholder={"Enter customer url"}
                    multiline
                    resizable={false}
                    borderless
                    onChange={(_, newValue) => setCustomerUrl(newValue || "")}
                />
                <DefaultButton className={styles.customerInputButton}
                               onClick={redirectToCustomerUrl}>Go</DefaultButton>
            </div>
        </div>
    );
};

export default SelectCustomer;
