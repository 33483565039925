import React, { useState, useEffect } from "react";
import { Checkbox, ICheckboxProps } from "@fluentui/react";

interface PersistentCheckboxProps extends ICheckboxProps {
    storageKey: string;
}

const PersistentCheckBox: React.FC<PersistentCheckboxProps> = props => {
    const [checked, setChecked] = useState<boolean>(false);

    // Load the stored value from local storage when the component mounts
    useEffect(() => {
        const storedValue = localStorage.getItem(props.storageKey);
        if (storedValue) {
            setChecked(storedValue === "true");
        }
    }, [props.storageKey]);

    const onChange = (_event: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, newChecked?: boolean) => {
        if (newChecked === undefined) return;
        // Update the value in the state and local storage
        setChecked(newChecked);
        localStorage.setItem(props.storageKey, String(newChecked));

        // Call the parent's onChange if it exists
        if (props.onChange) {
            props.onChange(_event, newChecked);
        }
    };

    return <Checkbox {...props} checked={checked} onChange={onChange} />;
};

export default PersistentCheckBox;
