import { ChangeEvent, useState } from "react";

interface params {
    onUpload: Function;
}
const MultipleFileUploader = (params: params) => {
    const [fileList, setFileList] = useState<FileList | null>(null);

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFileList(e.target.files);
    };

    const handleUploadClick = () => {
        if (!fileList) {
            return;
        }

        const data = new FormData();
        files.forEach((file, i) => {
            data.append(file.name, file, file.name);
        });

        params.onUpload(data);
    };

    const files = fileList ? [...fileList] : [];

    return (
        <div>
            <input type="file" onChange={handleFileChange} multiple />

            <ul>
                {files.map((file, i) => (
                    <li key={i}>
                        {file.name} - {file.type}
                    </li>
                ))}
            </ul>

            <button onClick={handleUploadClick}>Upload</button>
        </div>
    );
};

export default MultipleFileUploader;
