import { Outlet, NavLink, Link } from "react-router-dom";

import styles from "./Layout.module.css";

import { matchPath } from "react-router";
import { useEffect, useState } from "react";

interface Props {
    isAuthenticated: boolean;
}

const Layout = ({ isAuthenticated }: Props) => {
    const logout = () => {
        localStorage.removeItem("access_token");
        window.location.href = "/";
    };

    const [customerUrl, setCustomerUrl] = useState("");

    useEffect(() => {
        const match = matchPath("/customer/:customerUrl/", window.location.pathname);
        if (!match) return;
        const { customerUrl } = match.params;
        setCustomerUrl(customerUrl!);
    }, []);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <h3 className={styles.headerTitle}>Jepeto.ai | Golden Retriever</h3>
                    </Link>
                    <nav>
                        <ul className={styles.headerNavList}>
                            <li>
                                <NavLink
                                    to={`/customer/${customerUrl}/`}
                                    className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                >
                                    Chat
                                </NavLink>
                            </li>
                            <li className={styles.headerNavLeftMargin}>
                                <NavLink
                                    to={`/customer/${customerUrl}/qa`}
                                    className={({ isActive }) => (isActive ? styles.headerNavPageLinkActive : styles.headerNavPageLink)}
                                >
                                    Ask a question
                                </NavLink>
                            </li>

                            {isAuthenticated && (
                                <li className={styles.headerNavLeftMargin}>
                                    <NavLink to="#" className={styles.headerNavPageLink} onClick={logout}>
                                        Logout
                                    </NavLink>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>
            </header>

            <Outlet />
        </div>
    );
};

export default Layout;
