import React, { useEffect, useState } from "react";

import styles from "./filemanager.module.css";
import { getCustomerUrl } from "../../helpers/urlUtils";
import { FileArray } from "chonky";
import Uploader from "./Uploader/Uploader";
import { FileBrowser } from "./FileBrowser/FileBrowser";
import { getFiles } from "../../api";

const FileManager = () => {
    const [customerUrl, setCustomerUrl] = useState<string>("");
    const [files, setFiles] = useState<FileArray>([]);

    useEffect(() => {
        setCustomerUrl(getCustomerUrl());
    }, []);

    const loadFiles = async () => {
        const customerFiles = await getFiles({ url: customerUrl });
        setFiles(customerFiles);
    };

    useEffect(() => {
        if (!customerUrl) return;
        loadFiles();
    }, [customerUrl]);

    return (
        <div className={styles.filemanagerContainer}>
            <h1 className={styles.uploaderTitle}>Manage your files</h1>
            <div className={styles.fileBrowserContainer}>
                <FileBrowser customerUrl={customerUrl} files={files} />
            </div>
            <Uploader />
        </div>
    );
};

export default FileManager;
