// LoginModal.tsx
import React, { useState } from "react";
import { Modal, TextField, PrimaryButton } from "@fluentui/react";
import { MessageBar, MessageBarType } from "@fluentui/react";
import { loginApi } from "../api";

interface LoginModalProps {
    isModalOpen: boolean;
    setIsModalOpen: (isOpen: boolean) => void;
    setIsAuthenticated: (isAuthenticated: boolean) => void;
}

const LoginModal: React.FC<LoginModalProps> = props => {
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleLogin = async () => {
        try {
            const response = await loginApi({ username, password });
            localStorage.setItem("access_token", response.access_token);
            props.setIsAuthenticated(true);
            props.setIsModalOpen(false);
        } catch (error) {
            console.error("Login failed:", error);
            setErrorMessage("Login failed. Please check your credentials.");
        }
    };

    return (
        <Modal isOpen={props.isModalOpen} onDismiss={() => props.setIsModalOpen(false)}>
            <div style={{ padding: "20px" }}>
                <h2>Login</h2>
                {errorMessage && (
                    <MessageBar
                        messageBarType={MessageBarType.error}
                        isMultiline={false}
                        onDismiss={() => setErrorMessage(null)}
                        dismissButtonAriaLabel="Close"
                    >
                        {errorMessage}
                    </MessageBar>
                )}
                <TextField label="Username" value={username} onChange={(_, newValue) => setUsername(newValue || "")} />
                <TextField label="Password" type="password" value={password} onChange={(_, newValue) => setPassword(newValue || "")} />
                <PrimaryButton onClick={handleLogin} style={{ marginTop: "20px" }}>
                    Login
                </PrimaryButton>
            </div>
        </Modal>
    );
};

export default LoginModal;
