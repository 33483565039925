export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
};

export type CustomerRequest = {
    url: string;
};

export type UploadFilesRequest = {
    customerUrl: string;
    formData: FormData;
};

export type CustomerResponse = {
    approach?: Approaches;
    overrides?: AskRequestOverrides;
    error?: string;
};

export type LoginRequest = {
    username: string;
    password: string;
};

export type NormalResponse = {
    message?: string;
    error?: string;
};

export type LoginResponse = {
    access_token: string;
    error?: string;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    error?: string;
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
    customer_url: string;
};
