import React, { useState, useEffect } from "react";
import { SpinButton, ISpinButtonProps } from "@fluentui/react";

interface PersistentSpinButtonProps extends ISpinButtonProps {
    storageKey: string;
}

const PersistentSpinButton: React.FC<PersistentSpinButtonProps> = props => {
    const [value, setValue] = useState<string | undefined>("0");

    // Load the stored value from local storage when the component mounts
    useEffect(() => {
        const storedValue = localStorage.getItem(props.storageKey);
        if (storedValue) {
            setValue(storedValue);
        }
    }, [props.storageKey]);

    const onInputChange = (_ev: React.SyntheticEvent<HTMLElement, Event>, newValue: string | undefined) => {
        // Update the value in the state and local storage
        setValue(newValue);
        localStorage.setItem(props.storageKey, newValue || "");

        // Call the parent's onInputChange if it exists
        if (props.onChange) {
            props.onChange(_ev, newValue);
        }
    };

    return <SpinButton {...props} value={value} onChange={onInputChange} />;
};

export default PersistentSpinButton;
