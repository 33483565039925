import { FullFileBrowser, FileArray } from "chonky";

interface params {
    customerUrl: string;
    files: FileArray;
}

export const FileBrowser = ({ customerUrl, files }: params) => {
    return (
        <div style={{ height: 300 }}>
            <FullFileBrowser files={files} />
        </div>
    );
};
